import { useEffect, useState } from 'react';

import { TopicMetaProps } from '@tb-core/adapters/contentful/graphql/topic-metadata-adapter';
import { isDayPartNow } from '@tb-core/helpers/daypart';

export const useIsDaypartActive = (
    daypart: string | null,
    daypartsConfig?: Pick<TopicMetaProps, 'dayparts'>
) => {
    const [isDaypartActive, setIsDaypartActive] = useState<
        boolean | undefined
    >();

    useEffect(() => {
        setIsDaypartActive(
            !daypart || !daypartsConfig || isDayPartNow(daypart, daypartsConfig)
        );
    }, []);

    return [isDaypartActive];
};
