import { DetailedHTMLProps, VideoHTMLAttributes } from 'react';

import { assetPath } from '@tb-core/helpers/next-env';

export interface EmbeddedVideoProps
    extends DetailedHTMLProps<
        VideoHTMLAttributes<HTMLVideoElement>,
        HTMLVideoElement
    > {
    className?: string;
    mediaType: string;
    mobileContain?: boolean;
    source: string;
}

const EmbeddedVideo = ({
    children,
    className,
    mediaType,
    source,
    ...props
}: EmbeddedVideoProps) => (
    <video className={className} {...props}>
        <source src={`${assetPath}${source}`} type={`video/${mediaType}`} />
        {children}
    </video>
);

export default EmbeddedVideo;
