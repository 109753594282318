import classNames from 'classnames';

import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import { ResponsiveMediaProps } from '@tb-core/components/simple/responsive-media';

import styles from './styles.module.scss';

export enum Ratios {
    _1by1 = '1by1',
    _1by2 = '1by2',
    _2by1 = '2by1',
    _3by1 = '3by1',
    _3by2 = '3by2',
    _3by4 = '3by4',
    _4by3 = '4by3',
    _9by16 = '9by16',
    _16by9 = '16by9'
}

export type AspectRatioImageProps = ResponsiveMediaProps & {
    className?: string;
    ratio?: Ratios;
    mobileRatio?: Ratios;
};

// @TODO move to new component structure
const AspectRatioImage = ({
    className,
    ratio = Ratios._1by1,
    mobileRatio,
    ...responsiveImageProps
}: AspectRatioImageProps) => (
    <div className={classNames(styles['ratio-wrapper'], className)}>
        <div
            className={classNames(
                styles.ratio,
                styles[`ratio--${ratio}`],
                mobileRatio && styles[`mobile-ratio--${mobileRatio}`]
            )}
        ></div>
        <ResponsiveImage
            className={styles['ratio-content']}
            {...responsiveImageProps}
        />
    </div>
);

export default AspectRatioImage;
