import { SafeAnchorProps } from '@tb-core/components/behavior/safe-anchor';
import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';

export interface ConditionalLinkProps extends Omit<SafeAnchorProps, 'href'> {
    href?: string;
}

const ConditionalLink = ({ href, children, ...props }: ConditionalLinkProps) =>
    href ? (
        <SafeAnchorWithGaEvent href={href} {...props}>
            {children}
        </SafeAnchorWithGaEvent>
    ) : (
        <>{children}</>
    );

export default ConditionalLink;
