import { useEffect, useState } from 'react';

interface VideoProps {
    className?: string;
}

interface TwitchEmbedProps extends VideoProps {
    autoplay?: boolean;
    fullscreen?: boolean;
    channelId?: string;
    muted?: boolean;
    timeCodeStart?: string;
    videoId?: string;
}

const TwitchEmbed = ({
    autoplay,
    channelId,
    className,
    fullscreen,
    muted,
    timeCodeStart,
    videoId
}: TwitchEmbedProps) => {
    const videoType = channelId ? 'channel' : 'video';
    const id = channelId ? channelId : videoId;

    const [urlPath, setUrlPath] = useState('');

    useEffect(() => {
        // twitch needs parent url for embed to work
        // https://dev.twitch.tv/docs/embed/video-and-clips
        setUrlPath(window.location.hostname);
    }, [urlPath]);

    if (!urlPath) {
        return null;
    }

    return (
        <iframe
            allowFullScreen={fullscreen}
            className={className}
            src={`https://player.twitch.tv/?${videoType}=${id}&parent=${urlPath}&autoplay=${autoplay}&muted=${muted}&time=${timeCodeStart}`}
        ></iframe>
    );
};

export default TwitchEmbed;
