import classNames from 'classnames';

import styles from './styles.module.scss';

interface TitleDescriptionProps {
    description?: string;
    subtitle: null | string;
    subtitleClassName?: string;
    title?: string;
}

const TitleDescription = ({
    description,
    subtitle,
    subtitleClassName,
    title
}: TitleDescriptionProps) => (
    <>
        {title && <h2 className={styles.title}>{title}</h2>}
        {description && <p>{description}</p>}
        {subtitle && (
            <p className={classNames(subtitleClassName, styles.subtitle)}>
                {subtitle}
            </p>
        )}
    </>
);

export default TitleDescription;
