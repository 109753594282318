import classNames from 'classnames';

import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';
import StandardModule from '@tb-core/components/container/modules/standard-module';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import { getThemeFont } from '@tb-core/components/styled/modules/helpers/get-theme-fonts';
import StandardFaqContent from '@tb-core/components/styled/modules/standard-faq/standard-faq-content';
import { GenericModuleProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardFaq = ({
    backgroundImage,
    className,
    colorTheme,
    footnote,
    fragmentIdentifier,
    style,
    ...props
}: GenericModuleProps) => {
    const { isCollapsible } = props;
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        color: colorTheme?.containerText,
        ...style
    };

    const themeFontClassName = getThemeFont(colorTheme?.font);
    return (
        <>
            <StandardModule
                backgroundImage={backgroundImage as ResponsiveImageProps}
                body={<StandardFaqContent {...{ ...props, colorTheme }} />}
                className={classNames(
                    styles['standard-faq'],
                    themeFontClassName,
                    className,
                    {
                        [styles.collapsed]: isCollapsible
                    }
                )}
                fragmentIdentifier={fragmentIdentifier}
                style={theme}
            />

            {footnote && (
                <footer className={styles.footnote}>
                    <section>
                        <ContentfulRichTextWithTheme
                            linkedEntries={footnote?.links}
                            node={footnote.json}
                            renderOptions={{ renderText }}
                        />
                    </section>
                </footer>
            )}
        </>
    );
};

export default StandardFaq;
