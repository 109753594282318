import React from 'react';

interface HandleKeyDownProps {
    downEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    enterEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    escapeEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    event: KeyboardEvent | React.KeyboardEvent<HTMLElement>;
    leftEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    rightEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    spacebarEvent?: (
        e: KeyboardEvent | React.KeyboardEvent<HTMLElement>
    ) => void;
    tabEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
    upEvent?: (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void;
}

const keyDownHandler = ({
    downEvent,
    enterEvent,
    escapeEvent,
    event,
    leftEvent,
    rightEvent,
    spacebarEvent,
    tabEvent,
    upEvent
}: HandleKeyDownProps) => {
    switch (event.key) {
        case 'Enter':
            if (enterEvent) {
                enterEvent(event);
            }
            break;
        case 'Esc':
        case 'Escape':
            if (escapeEvent) {
                escapeEvent(event);
            }
            break;
        case ' ':
        case 'Spacebar':
            if (spacebarEvent) {
                spacebarEvent(event);
            }
            break;
        case '  ':
        case 'Tab':
            if (tabEvent) {
                tabEvent(event);
            }
            break;
        case 'Up':
        case 'ArrowUp':
            if (upEvent) {
                upEvent(event);
            }
            break;
        case 'Down':
        case 'ArrowDown':
            if (downEvent) {
                downEvent(event);
            }
            break;
        case 'Left':
        case 'ArrowLeft':
            if (leftEvent) {
                leftEvent(event);
            }
            break;
        case 'Right':
        case 'ArrowRight':
            if (rightEvent) {
                rightEvent(event);
            }
            break;
    }
};

export default keyDownHandler;
