import { ThemableLinkButtonProps } from '@tb-core/components/styled/links/themable-link-button';
import { ColorThemeProps } from '@tb-core/types';

/**
 * Takes the `ctaGroup` and `ColorTheme` objects and merges in the...
 *      ...selected ColorTheme options as { CSSProperties }.
 * @returns {ThemableLinkButtonProps[]}
 *
 */
export const mergeThemeIntoCtagroup = (
    ctaGroup: ThemableLinkButtonProps[],
    colorTheme: ColorThemeProps
) => {
    if (!ctaGroup) {
        return;
    }

    if (ctaGroup[0]) {
        ctaGroup[0].colorTheme = {
            backgroundColor: colorTheme?.primaryButtonBackground,
            borderColor: colorTheme?.primaryButtonBorder,
            color: colorTheme?.primaryButtonText
        };
    }

    if (ctaGroup[1]) {
        ctaGroup[1].colorTheme = {
            backgroundColor: colorTheme?.secondaryButtonBackground,
            borderColor: colorTheme?.secondaryButtonBorder,
            color: colorTheme?.secondaryButtonText
        };
    }

    return ctaGroup;
};
