import classNames from 'classnames';

import EmbeddedVideo, {
    EmbeddedVideoProps
} from '@tb-core/components/simple/embedded-video';

import styles from './styles.module.scss';

export type BackgroundHeroVideoProps = EmbeddedVideoProps;

const BackgroundHeroVideo = ({
    className,
    ...props
}: BackgroundHeroVideoProps) => (
    <EmbeddedVideo
        {...props}
        autoPlay
        className={classNames(styles.video, className)}
        key={props.source}
        loop
        muted
        playsInline
    />
);

export default BackgroundHeroVideo;
