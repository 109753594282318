import classNames from 'classnames';

import ItemList from '@tb-core/components/simple/item-list';
import { ColorThemeProps } from '@tb-core/types';

interface ScrollableControlsProps {
    active?: number;
    className?: string;
    classNameActive?: string;
    classNameContainer?: string;
    colorTheme?: ColorThemeProps;
    disabled?: boolean;
    length: number;
    handler: (slideIndex: number) => void;
}

export interface NavButtonProps {
    disabled?: boolean;
    className?: string;
    onClick: () => void;
    i: number;
}

const NavButton = ({ i, ...props }: NavButtonProps) => (
    <button {...props}>{`slide ${i}`}</button>
);

const ScrollableCarouselControls = ({
    active = 0,
    className,
    classNameActive,
    classNameContainer,
    colorTheme,
    disabled,
    length,
    handler
}: ScrollableControlsProps) => {
    const theme = {
        color: colorTheme?.moduleUiInactive
    };
    const themeActive = {
        color: colorTheme?.moduleUiActive
    };
    const navDotsProps = [];
    const navDotsToShow = Math.ceil(length);
    for (let i = 0; i < navDotsToShow; i++) {
        navDotsProps.push({
            className: classNames(className, active === i && classNameActive),
            disabled,
            i,
            key: i,
            onClick: () => {
                handler(i);
            },
            style: {
                ...theme,
                ...(active === i && themeActive)
            }
        });
    }

    return (
        <div className={classNames(classNameContainer)}>
            <ItemList item={NavButton} itemProps={[...navDotsProps]} />
        </div>
    );
};

export default ScrollableCarouselControls;
