import classNames from 'classnames';

import ScrollableCarousel from '@tb-core/components/behavior/scrollable-carousel';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import FaqContentLink from '@tb-core/components/styled/faq-content-link';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import {
    childItemAdapter,
    ctaGroupAdapter
} from '@tb-core/components/styled/modules/helpers';
import { carouselConfigDefaults } from '@tb-core/components/styled/modules/standard-carousel/standard-carousel';
import StandardCarouselItem from '@tb-core/components/styled/modules/standard-carousel/standard-carousel-item';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import usePageContext from '@tb-core/hooks/use-page-context';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface StandardContentProps extends WebpageContentProps {
    className?: string;
}

const StandardCarouselContent = ({
    className,
    carouselConfig = carouselConfigDefaults,
    colorTheme,
    contentLink,
    description,
    entriesCollection: { items },
    footnote,
    title,
    type
}: StandardContentProps) => {
    const {
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;
    const { Link, WebpageContent } = entriesByTypename(items);

    const ctaGroup = ctaGroupAdapter(
        colorTheme,
        {
            'Analytics-Action': type,
            'Analytics-Value': title
        },
        Link
    );

    const carouselItems = childItemAdapter({
        colorTheme,
        dataLayerTemplate: {
            'Analytics-Action': type
        },
        daypartsConfig,
        moduleContent: WebpageContent,
        moduleProps: carouselConfig
    });

    const ctaLimit = 2;
    const titleTheme = {
        color: colorTheme?.titleText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };

    const displayTitle = title && interpolate(title, tokenStrings);

    return (
        <>
            {contentLink && (
                <FaqContentLink
                    className={styles['content-link']}
                    gaDataLayerConfig={contentLink?.gaDataLayerConfig}
                    href={contentLink.url}
                    label={contentLink.label}
                />
            )}

            <div className={className}>
                {displayTitle && (
                    <Heading
                        dangerouslySetInnerHTML={{ __html: displayTitle }}
                        style={titleTheme}
                        tag="h2"
                    />
                )}

                {description?.json && (
                    <div>
                        <ContentfulRichTextWithTheme
                            colorTheme={colorTheme}
                            node={description.json}
                            renderOptions={{ renderText }}
                        />
                    </div>
                )}

                {carouselItems && (
                    <>
                        <ScrollableCarousel
                            {...carouselConfig}
                            className={classNames(
                                styles['standard-carousel-scrollable'],
                                {
                                    [styles['carousel-items-pair']]:
                                        carouselItems.length === 2
                                }
                            )}
                            slide={StandardCarouselItem}
                            slideProps={carouselItems}
                        />
                    </>
                )}

                {/* CTA Group */}
                {ctaGroup && (
                    <FlexGridLayout
                        align="stretch"
                        className={styles['standard-carousel-nav']}
                        justify="center"
                    >
                        <BrandedCtaGroup
                            ctaGroup={ctaGroup.slice(0, ctaLimit)}
                        />
                    </FlexGridLayout>
                )}

                {footnote?.json && (
                    <footer style={footerTheme}>
                        <ContentfulRichTextWithTheme
                            node={footnote.json}
                            renderOptions={{ renderText }}
                        />
                    </footer>
                )}
            </div>
        </>
    );
};
export default StandardCarouselContent;
