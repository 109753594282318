import { FC } from 'react';

import { MenuSvgTilesProps } from '@tb-core/components/products/styled/menu-svg-tiles';
import ItemList from '@tb-core/components/simple/item-list';
import StandardHero from '@tb-core/components/styled/banners/standard-hero';
import LoyaltyVotingCarousel from '@tb-core/components/styled/modules/loyalty-voting-results';
import StandardBanner from '@tb-core/components/styled/modules/standard-banner';
import StandardBucket from '@tb-core/components/styled/modules/standard-bucket';
import StandardCarousel from '@tb-core/components/styled/modules/standard-carousel';
import StandardFaq from '@tb-core/components/styled/modules/standard-faq';
import StandardText from '@tb-core/components/styled/modules/standard-text';
import StandardTile from '@tb-core/components/styled/modules/standard-tile';
import StandardVideo from '@tb-core/components/styled/modules/standard-video/standard-video';
import { isDayPartNow } from '@tb-core/helpers/daypart';
import usePageContext from '@tb-core/hooks/use-page-context';
import {
    GenericContentProps,
    GenericContentType,
    GenericModuleProps
} from '@tb-core/types';

export type ContentModule =
    | FC<GenericContentProps>
    | FC<GenericModuleProps>
    | FC<MenuSvgTilesProps>;

export type ContentModules = Record<string, ContentModule>;

export interface ContentModuleListProps {
    includeHero?: boolean;
    modules: ContentModules;
}

/**
 * Modules are mapped from Contentful WebpageContent field "Type"
 * to the corresponding component. If more types are added in Contentful
 * add the type and component here.
 */
export const contentModuleLookup: ContentModules = {
    banner: StandardBanner,
    bucket: StandardBucket,
    carousel: StandardCarousel,
    faq: StandardFaq,
    hero: StandardHero,
    text: StandardText,
    tile: StandardTile,
    video: StandardVideo,
    votingcarousel: LoyaltyVotingCarousel
};

const ContentModuleList = ({
    includeHero = true,
    modules
}: ContentModuleListProps) => {
    const {
        colorTheme,
        contentGroupCollection: { items },
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;

    const { collapseFaq } =
        usePageContext().content.pageData.topicMeta.generic || {};

    const moduleItems = items.filter((item: GenericContentProps) => {
        const isDaypartActive =
            !item.daypart || isDayPartNow(item.daypart, daypartsConfig);
        return includeHero
            ? isDaypartActive
            : isDaypartActive && item?.type?.toLowerCase() !== 'hero';
    });

    const contentComponent = (item: GenericContentProps, i: number) => {
        const type = item.type || 'fallback';
        const Component = modules[type.toLowerCase() as GenericContentType];
        // if item does not have colorTheme, inherit Page colorTheme
        if (colorTheme && item.colorTheme === null) {
            item.colorTheme = colorTheme;
        }

        if (collapseFaq) {
            item.isCollapsible = true;
        }

        return Component && <Component key={i} {...item} />;
    };

    return <ItemList item={contentComponent} itemProps={moduleItems} />;
};

export default ContentModuleList;
