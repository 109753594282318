import Script from 'next/script';
import { useState } from 'react';

import ResponsiveImage, {
    ResponsiveImageProps
} from '@tb-core/components/composites/responsive-image';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import {
    TwitchEmbedConstructor,
    TwitchEmbedConstructorOptions,
    TwitchPlayerConstructor
} from '@tb-core/types/twitch.d';

import styles from './styles.module.scss';

interface TwitchEmbedEverythingProps extends TwitchEmbedConstructorOptions {
    channelId?: string;
    errorImage?: ResponsiveImageProps;
    errorMessage?: string;
    timeCodeStart?: string;
    twitchEmbedApiUrl?: string;
    videoId?: string;
}

declare var Twitch: {
    Embed?: TwitchEmbedConstructor;
    Player?: TwitchPlayerConstructor;
};

const TwitchEmbedEverything = ({
    allowfullscreen,
    autoplay,
    channelId,
    collection,
    controls,
    errorImage,
    errorMessage,
    height,
    layout,
    muted,
    parent,
    theme,
    timeCodeStart,
    twitchEmbedApiUrl,
    videoId,
    width
}: TwitchEmbedEverythingProps) => {
    const [isError, setIsError] = useState(false);
    /*
     ** Add a defaults object instead of the following.
     */
    const embedOptions = {
        allowfullscreen: allowfullscreen || true,
        autoplay: autoplay || true,
        ...(channelId && { channel: channelId }),
        ...(collection && videoId
            ? { collection, video: videoId }
            : { collection }),
        controls: controls || true,
        height: height || '100%',
        layout: layout || 'video-with-chat',
        muted: muted || true,
        ...(parent && { parent }),
        theme: theme || 'dark',
        time: timeCodeStart || '0h0m0s',
        ...(videoId && { video: videoId }),
        width: width || '100%'
    };

    const elId = `twitch-embed-everything-${channelId ||
        collection ||
        videoId}`;

    return (
        <>
            <Script
                id="stripe-js"
                src={twitchEmbedApiUrl || 'https://embed.twitch.tv/embed/v1.js'}
                onError={() => {
                    setIsError(true);
                }}
                onLoad={() => {
                    return (
                        Twitch?.Embed && new Twitch.Embed(elId, embedOptions)
                    );
                }}
            />
            <div className={styles.container}>
                <div className={styles.positioning}>
                    {isError ? (
                        <FlexGridLayout
                            align="center"
                            direction="col"
                            justify="center"
                            className={styles.error}
                        >
                            {errorImage && <ResponsiveImage {...errorImage} />}
                            {errorMessage && <p>{errorMessage}</p>}
                        </FlexGridLayout>
                    ) : (
                        <div className={styles.content} id={elId} />
                    )}
                </div>
            </div>
        </>
    );
};

export default TwitchEmbedEverything;
