import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import ModuleContent from '@tb-core/components/styled/modules/module-content';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardBannerContent = ({
    colorTheme,
    horizontalPosition = 'Left',
    ...props
}: WebpageContentProps) => {
    const theme = {
        color: colorTheme?.containerText
    };
    return (
        <FlexGridLayout
            align="stretch"
            className={classNames(
                styles.content,
                horizontalPosition && styles[horizontalPosition.toLowerCase()]
            )}
            direction="col"
            style={theme}
        >
            <ModuleContent {...{ ...props, colorTheme }} />
        </FlexGridLayout>
    );
};

export default StandardBannerContent;
