import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ConditionalLink from '@tb-core/components/simple/links/conditional-link';
import AspectRatioImage, {
    Ratios
} from '@tb-core/components/styled/aspect-ratio-image';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GenericChildContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export enum SlideClass {
    'two-up' = 2,
    'three-up' = 3,
    'four-up' = 4
}

export interface StandardCarouselItemProps extends GenericChildContentProps {
    slidesToShowDesktop?: number;
}

const StandardCarouselItem = (
    {
        backgroundImage,
        className,
        colorTheme,
        contentLink,
        description,
        entriesCollection: { items },
        gaDataLayerConfig,
        itemTitle: title,
        slidesToShowDesktop,
        subtitle
    }: StandardCarouselItemProps,
    index?: number // passed from ItemList
) => {
    const { Link } = entriesByTypename(items);
    const ctaGroup =
        Link && ctaGroupAdapter(colorTheme, gaDataLayerConfig, Link);
    const ctaLimit = 1;

    const theme = {
        color: colorTheme?.containerText
    };

    const displayTitle = title && interpolate(title, tokenStrings);

    const handleTrack = () =>
        track('Clicked Module', {
            cta: contentLink?.url,
            header_text: displayTitle,
            module_type: 'Carousel',
            page_path: window.location.pathname
        });

    return (
        <article
            className={classNames(className, {
                [styles[
                    SlideClass[slidesToShowDesktop as number]
                ]]: slidesToShowDesktop
            })}
            key={index}
            style={theme}
            tabIndex={contentLink ? -1 : 0}
        >
            {backgroundImage && (
                <ConditionalLink
                    gaDataLayerConfig={gaDataLayerConfig}
                    href={contentLink?.url}
                    onClick={handleTrack}
                >
                    <AspectRatioImage
                        className={styles['scrollable-item-image']}
                        ratio={Ratios._1by1}
                        {...backgroundImage}
                    />
                </ConditionalLink>
            )}
            <div
                className={classNames({
                    [styles['scrollable-item-with-cta']]: ctaGroup
                })}
            >
                {displayTitle && (
                    <Heading tag="h3">
                        {contentLink?.url ? (
                            <ConditionalLink
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                                gaDataLayerConfig={gaDataLayerConfig}
                                href={contentLink.url}
                                onClick={handleTrack}
                            />
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                            />
                        )}
                        {subtitle && <small>{subtitle}</small>}
                    </Heading>
                )}

                {description && (
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                )}

                {/* CTA Group */}
                {ctaGroup && (
                    <FlexGridLayout
                        align="stretch"
                        className={styles['standard-carousel-btn']}
                        justify="center"
                    >
                        <BrandedCtaGroup
                            ctaGroup={ctaGroup.slice(0, ctaLimit)}
                        />
                    </FlexGridLayout>
                )}
            </div>
        </article>
    );
};
export default StandardCarouselItem;
