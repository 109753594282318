import classNames from 'classnames';
import { useState } from 'react';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Svg from '@tb-core/components/simple/svg';
import { tokenStrings } from '@tb-core/components/styled/contentful-rich-text-with-theme';
import FaqContentLink from '@tb-core/components/styled/faq-content-link';
import StandardFaqItems from '@tb-core/components/styled/modules/standard-faq/standard-faq-items';
import TitleDescription from '@tb-core/components/styled/title-description';
import { interpolate } from '@tb-core/helpers/interpolate';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface StandardFaqContentProps extends WebpageContentProps {
    className?: string;
}

const StandardFaqContent = ({
    className,
    contentLink,
    entriesCollection: { items },
    isCollapsible,
    subtitle,
    title
}: StandardFaqContentProps) => {
    const [showAccordion, setShowAccordion] = useState(false);

    const handleAccordion = () => setShowAccordion(!showAccordion);

    const displayTitle = title && interpolate(title, tokenStrings);
    const displaySubtitle = subtitle && interpolate(subtitle, tokenStrings);

    return (
        <>
            {contentLink && (
                <FaqContentLink
                    className={styles['content-link']}
                    gaDataLayerConfig={contentLink?.gaDataLayerConfig}
                    href={contentLink.url}
                    label={contentLink?.label}
                />
            )}

            <div
                className={classNames(
                    className,
                    styles['standard-faq-content'],
                    {
                        [styles.collapsed]: isCollapsible
                    }
                )}
            >
                {isCollapsible && displayTitle ? (
                    <div
                        className={styles['faq-header']}
                        onClick={handleAccordion}
                    >
                        <TitleDescription
                            subtitle={null}
                            subtitleClassName={styles['faq-subtitle']}
                            title={displayTitle}
                        />
                        <Svg
                            className={classNames(styles['faq-arrow'], {
                                [styles['open']]: showAccordion
                            })}
                            size="1.5em"
                            svgId="icon-arrow-down-alt"
                        />
                    </div>
                ) : (
                    <TitleDescription
                        subtitle={displaySubtitle}
                        subtitleClassName={styles['faq-subtitle']}
                        title={displayTitle}
                    />
                )}

                {items && (
                    <FlexGridLayout
                        align="start"
                        className={styles['standard-faq']}
                        justify="center"
                        wrap
                    >
                        <StandardFaqItems
                            {...{ isCollapsible, items, showAccordion }}
                        />
                    </FlexGridLayout>
                )}
            </div>
        </>
    );
};
export default StandardFaqContent;
