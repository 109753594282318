import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ConditionalLink from '@tb-core/components/simple/links/conditional-link';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import ExcerptFooter from '@tb-core/components/styled/excerpt-footer';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GenericChildContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardBucketItem = (
    {
        backgroundImage,
        colorTheme,
        contentLink,
        description,
        entriesCollection: { items },
        footnoteExcerpt,
        footnote,
        gaDataLayerConfig,
        itemTitle: title
    }: GenericChildContentProps,
    index?: number // passed from ItemList
) => {
    const { Link } = entriesByTypename(items);
    const ctaGroup =
        Link && ctaGroupAdapter(colorTheme, gaDataLayerConfig, Link);
    const ctaLimit = 1;
    const theme = {
        color: colorTheme?.containerText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };

    const displayTitle = title && interpolate(title, tokenStrings);

    const isFootnoteAvailable = footnote?.json || footnoteExcerpt?.json;

    return (
        <article key={index} style={theme}>
            {backgroundImage && (
                <ConditionalLink
                    gaDataLayerConfig={gaDataLayerConfig}
                    href={contentLink?.url}
                >
                    <ResponsiveImage {...backgroundImage} />
                </ConditionalLink>
            )}
            <div>
                {displayTitle && (
                    <Heading tag="h3">
                        {contentLink?.url ? (
                            <ConditionalLink
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                                gaDataLayerConfig={gaDataLayerConfig}
                                href={contentLink.url}
                            />
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                            />
                        )}
                    </Heading>
                )}

                {description && (
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                )}

                {/* CTA Group */}
                {ctaGroup && (
                    <FlexGridLayout
                        align="stretch"
                        className={styles['standard-bucket-nav']}
                        justify="center"
                    >
                        <BrandedCtaGroup
                            ctaGroup={ctaGroup.slice(0, ctaLimit)}
                        />
                    </FlexGridLayout>
                )}
            </div>

            {isFootnoteAvailable && (
                <ExcerptFooter
                    {...{ footnote, footnoteExcerpt, footerTheme }}
                />
            )}
        </article>
    );
};

export default StandardBucketItem;
