import classNames from 'classnames';
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

import styles from './styles.module.scss';

export interface VideoProps {
    containerClassName?: string;
    enableControls?: boolean;
}

export interface YouTubeVideoProps extends VideoProps {
    className?: string;
    includeChat?: boolean;
    videoId: string;
}

const YouTubeVideo = ({
    className,
    containerClassName,
    enableControls = true,
    includeChat,
    videoId
}: YouTubeVideoProps) => {
    if (!videoId) {
        return null;
    }

    const [hostname, setHostname] = useState<string | undefined>();

    useEffect(() => {
        setHostname(window.location.hostname);
    }, []);

    const YtPlayer = () => (
        <YouTube
            containerClassName={classNames([
                containerClassName,
                styles['container-yt']
            ])}
            opts={{
                height: '100%',
                playerVars: {
                    controls: enableControls ? 1 : 0,
                    enablejsapi: 0,
                    modestbranding: 1, // 0 shows related videos, 1 restricts to same channel
                    rel: 0
                },
                width: '100%'
            }}
            videoId={videoId}
        />
    );

    return (
        <>
            {includeChat && hostname ? (
                <div className={styles['yt-live-container']}>
                    <div className={styles['yt-live-video']}>
                        <div className={styles.positioning}>
                            <div className={styles.content}>
                                <YtPlayer />
                            </div>
                        </div>
                    </div>
                    <div className={styles['yt-live-chat']}>
                        <iframe
                            referrerPolicy="origin"
                            src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${hostname}`}
                        />
                    </div>
                </div>
            ) : (
                <div className={classNames([className, styles.container])}>
                    <YtPlayer />
                </div>
            )}
        </>
    );
};

export default YouTubeVideo;
