export interface HoursAndMinutes {
    hours: number;
    minutes: number;
}

/**
 * Checks if the current time falls in the range of a specified start and end time
 * @param startTime
 * @param endTime
 */
export const currentTimeIsBetween = (
    startTime: HoursAndMinutes,
    endTime: HoursAndMinutes
) => {
    const dateNow = Date.now();
    const dateStartTime = getDaypartDate(startTime.hours, startTime.minutes);
    const dateEndTime = getDaypartDate(endTime.hours, endTime.minutes);

    return (
        dateNow >= dateStartTime.getTime() && dateNow < dateEndTime.getTime()
    );
};
export const getLocalTimezone = () =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * @param UTCDateTime datetime in UTC format from contenful
 * @returns Pacific time converted date
 */
export const convertUTCDateTime = (
    UTCDateTime: string,
    setToPtZone?: boolean
): Date => {
    const setOffset = (timeZone: string) => {
        return new Date()
            .toLocaleString('en-US', {
                timeZone,
                // Typescript was update to add longOffset, but not in our version
                // https://github.com/microsoft/TypeScript/issues/48391
                // @ts-ignore

                timeZoneName: 'longOffset'
            })
            .split('GMT')[1];
    };

    return new Date(
        `${UTCDateTime.replace('Z', '')}${setOffset(
            setToPtZone ? 'America/Los_Angeles' : getLocalTimezone()
        )}`
    );
};

/**
 * @param hours
 * @param minutes
 * @return current Date with provided hours and minutes
 */
// TODO: Create a non MVP ticket to add a unit test
const getDaypartDate = (hours: number, minutes: number) => {
    const date = new Date();
    date.setMinutes(minutes);
    date.setHours(hours);
    return date;
};

/**
 * This function converts the passed in argument to 24 hour format
 *
 * @param ampm - indicates if time is 'AM' or 'PM'
 * @param date - date to be converted to 24 hour format
 * @return date - the formatted date
 */
// TODO: Create a non MVP ticket to add a unit test
const getFormattedDate = (ampm: string, date: Date) => {
    date.setHours(
        ampm.includes('PM') && date.getHours() !== 12
            ? date.getHours() + 12
            : date.getHours()
    );
    return date;
};

/**
 * This function subtracts minutes from the passed in date and constructs it as
 * a new date
 *
 * @param date - the date to be modified
 * @param minutes - the minutes to be subtracted from the date
 * @return the newly modified date
 */
// TODO: Create a non MVP ticket to add a unit test
const getSubtractedDate = (date: Date, minutes: number) => {
    return new Date(date.getTime() - minutes);
};

/**
 * This function converts the passed in date to a specific time zone
 * (Assumes only US time zones)
 *
 * @param date - the date to be converted
 * @param timeZone - the time zone (ex: GMT-07:00)
 * @return date - the converted date
 */
// TODO: Create a non MVP ticket to add a unit test
const getLocalTime = (date: Date, timeZone: string) => {
    // TODO: Have this function also work for international timezones as well
    // recommended to move away from string manipulation for the date and to use
    // new Date().getTimezoneOffset() / 60 where TimezeoneOffset is a +/- value in minutes
    // May want to use minutes instead of hours as offsets may have 30/15 increments.
    const timeZoneOffset = new Date().getTimezoneOffset();
    const localTimeZone = timeZone
        ? timeZone
        : timeZoneOffset < 0
        ? `GMT${timeZoneOffset}`
        : `GMT+${timeZoneOffset}`;
    const timeZoneHour = localTimeZone.includes('-')
        ? localTimeZone.split('-')[1].split(':')[0]
        : localTimeZone.split('+')[1].split(':')[0];
    const localTimeZoneHour = date
        .toString()
        .split(/\+|\-/)[1]
        .substring(0, 2);

    if (timeZoneHour > localTimeZoneHour) {
        date.setHours(
            date.getHours() -
                Math.abs(
                    parseInt(timeZoneHour, 10) - parseInt(localTimeZoneHour, 10)
                )
        );
    } else {
        date.setHours(
            date.getHours() +
                Math.abs(
                    parseInt(timeZoneHour, 10) - parseInt(localTimeZoneHour, 10)
                )
        );
    }

    return date;
};

export const leadingZero = (s: string | number) => `0${s}`.slice(-2);

const formatDate = (d: Date) =>
    `${d.getFullYear()}-${leadingZero(d.getMonth() + 1)}-${leadingZero(
        d.getDate()
    )}`;

/**
 * convert datetime string to simplified timestamp of the calendar date
 *
 * @param ISO - the ISO / datetime string
 * @return timestamp of the base date yyyy-mm-dd
 */
const getTimeForSimpleDate = (ISO?: string) => {
    const d = ISO ? new Date(ISO) : new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
};

const isDatePast = (d: string) => {
    const now = getTimeForSimpleDate();
    const then = getTimeForSimpleDate(d);

    return now > then;
};

export {
    formatDate,
    getDaypartDate,
    getFormattedDate,
    getLocalTime,
    getSubtractedDate,
    isDatePast
};
