import { MutableRefObject, useEffect, useState } from 'react';
import { useDebounced } from '@tb-core/hooks/use-debounced';

interface Scroll {
    scrollX?: number;
    scrollY?: number;
}

// @TODO: refactor timeout to https://gist.githubusercontent.com/jamesfulford/de7b5e5f37d97595e0626cca6f2002e0/raw/f5fda29af248607dd2d44c2b18e094ace1a2d065/useTimeout.ts

/**
 * Returns the current height, width of the browser onResize, throttled by delay
 * @example
 *      const { x, y } = useScollPosition(300);
 */
type ScrollRef = MutableRefObject<Element>;

const useScrollPosition = (
    ele: ScrollRef | Window = window,
    delay: number = 750
) => {
    const [scroll, setScroll] = useState<Scroll>({
        scrollX: undefined,
        scrollY: undefined
    });

    const updateScrollPosition = () => {
        const pos = {
            scrollX:
                (ele as ScrollRef).current?.scrollLeft ||
                (ele as Window).scrollX ||
                0,
            scrollY:
                (ele as ScrollRef).current?.scrollTop ||
                (ele as Window).scrollY ||
                0
        };
        setScroll(pos);
    };

    const onScroll = useDebounced(updateScrollPosition, delay);

    useEffect(() => {
        ((ele as ScrollRef).current || ele).addEventListener(
            'scroll',
            onScroll
        );
        updateScrollPosition();

        return () =>
            ((ele as ScrollRef).current || ele).removeEventListener(
                'scroll',
                onScroll
            );
    }, []);

    return scroll;
};

export default useScrollPosition;
