import classNames from 'classnames';

import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';
import StandardModule from '@tb-core/components/container/modules/standard-module';
import { getThemeFont } from '@tb-core/components/styled/modules/helpers/get-theme-fonts';
import { GenericModuleProps } from '@tb-core/types';
import StandardTileContent from './standard-tile-content';

import styles from './styles.module.scss';

const StandardTile = ({
    backgroundImage,
    className,
    colorTheme,
    fragmentIdentifier,
    style,
    ...props
}: GenericModuleProps) => {
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        color: colorTheme?.containerText,
        ...style
    };

    const themeFontClassName = getThemeFont(colorTheme?.font);

    return (
        <StandardModule
            className={classNames(
                styles['standard-tile'],
                themeFontClassName,
                className
            )}
            backgroundImage={backgroundImage as ResponsiveImageProps}
            body={
                <StandardTileContent
                    {...{ ...props, colorTheme }}
                    className={styles.content}
                />
            }
            fragmentIdentifier={fragmentIdentifier}
            style={theme}
        />
    );
};

export default StandardTile;
