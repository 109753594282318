import ResponsiveVideo, {
    ResponsiveVideoProps
} from '@tb-core/components/behavior/responsive-video';
import ResponsiveImage, {
    ResponsiveImageProps
} from '@tb-core/components/composites/responsive-image';
import BackgroundCoverVideo from '@tb-core/components/styled/background-cover-video';
import BackgroundHeroVideo from '@tb-core/components/styled/background-hero-video';

export type ResponsiveMediaProps =
    | ResponsiveImageProps
    | Pick<
          ResponsiveVideoProps,
          'mediaType' | 'mobileContain' | 'mobileUrl' | 'url'
      >;

const ResponsiveMedia = (background: ResponsiveMediaProps) => {
    if ('src' in background) {
        return <ResponsiveImage {...background} />;
    } else if ('url' in background) {
        const { mobileContain, ...videoProps } = background;
        return (
            <ResponsiveVideo
                {...videoProps}
                breakpoint={640}
                Video={
                    mobileContain ? BackgroundHeroVideo : BackgroundCoverVideo
                }
            />
        );
    }
    return <></>;
};

export default ResponsiveMedia;
