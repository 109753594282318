import classnames from 'classnames';
import { useRouter } from 'next/router';
import { CSSProperties } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import ConditionalLink from '@tb-core/components/simple/links/conditional-link';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import setModuleAmplitudeEvent from '@tb-core/components/styled/modules/helpers/set-module-amplitude-event';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GenericChildContentProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardTileItem = (
    {
        backgroundImage,
        colorTheme,
        contentLink,
        description,
        entriesCollection: { items },
        footnote,
        gaDataLayerConfig,
        itemTitle: title,
        wordmark
    }: GenericChildContentProps,
    index?: number // passed from ItemList
) => {
    /* Separate entries by Links and WebpageContent */
    const { Link } = entriesByTypename(items);
    const ctaGroup =
        Link && ctaGroupAdapter(colorTheme, gaDataLayerConfig, Link);
    const ctaLimit = 1;
    const shouldRenderLinksAsList = Link && Link.length > ctaLimit;
    const theme: CSSProperties = {
        color: colorTheme?.containerText
    };
    const titleTheme = {
        color: colorTheme?.titleText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };
    const displayTitle = title && interpolate(title as string, tokenStrings);

    const linkItem: ListItem = (
        { gaDataLayerConfig, label = '', url }: LinkProps,
        i
    ) => {
        const pagePath = useRouter().asPath;
        const handleClick = () => {
            setModuleAmplitudeEvent({ gaDataLayerConfig, label }, pagePath);
        };

        return label ? (
            <li>
                <SafeAnchorWithGaEvent
                    {...{
                        gaDataLayerConfig
                    }}
                    href={url}
                    key={i}
                    onClick={handleClick}
                >
                    {label}
                </SafeAnchorWithGaEvent>
            </li>
        ) : null;
    };
    return (
        <article key={index} style={theme}>
            {backgroundImage && (
                <ConditionalLink
                    gaDataLayerConfig={gaDataLayerConfig}
                    href={contentLink?.url}
                >
                    <ResponsiveImage {...backgroundImage} />
                </ConditionalLink>
            )}
            <div>
                {wordmark && (
                    <ResponsiveImage
                        className={styles.wordmark}
                        {...wordmark}
                    />
                )}
                {displayTitle && (
                    <Heading tag="h3" style={titleTheme}>
                        {contentLink?.url ? (
                            <ConditionalLink
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                                gaDataLayerConfig={gaDataLayerConfig}
                                href={contentLink.url}
                            />
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                            />
                        )}
                    </Heading>
                )}

                {description && (
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                )}

                {ctaGroup && (
                    <FlexGridLayout
                        align="start"
                        className={classnames(
                            styles['standard-tile-item-nav'],
                            {
                                [styles[
                                    'standard-tile-item-nav-row'
                                ]]: shouldRenderLinksAsList
                            }
                        )}
                        direction={shouldRenderLinksAsList ? 'row' : 'col'}
                        justify="start"
                        wrap={true}
                    >
                        {shouldRenderLinksAsList ? (
                            <ul>
                                <ItemList item={linkItem} itemProps={items} />
                            </ul>
                        ) : (
                            <BrandedCtaGroup
                                ctaGroup={ctaGroup.slice(0, ctaLimit)}
                            />
                        )}
                    </FlexGridLayout>
                )}

                {footnote?.json && (
                    <footer style={footerTheme}>
                        <ContentfulRichTextWithTheme
                            node={footnote.json}
                            renderOptions={{ renderText }}
                        />
                    </footer>
                )}
            </div>
        </article>
    );
};
export default StandardTileItem;
