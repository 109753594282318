import classNames from 'classnames';
import { forwardRef } from 'react';

import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

export type ScrollableProps = DivProps;

const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>(
    ({ children, className, ...props }: ScrollableProps, forwardedRef) => (
        <div
            ref={forwardedRef}
            {...props}
            className={classNames(className, styles.scroll)}
        >
            {children}
        </div>
    )
);

export default Scrollable;
