import classnames from 'classnames';
import { useRouter } from 'next/router';
import { CSSProperties } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import ConditionalLink from '@tb-core/components/simple/links/conditional-link';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import ExcerptFooter from '@tb-core/components/styled/excerpt-footer';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import setModuleAmplitudeEvent from '@tb-core/components/styled/modules/helpers/set-module-amplitude-event';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GenericChildContentProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

/**
 * TODO: https://tacobeta.atlassian.net/browse/ECDISC-565
 * Shared module layout component for content modules, Banner, Tile and Bucket
 */

const ModuleItem = (
    {
        backgroundImage,
        colorTheme,
        contentLink,
        description,
        entriesCollection: { items },
        footnote,
        footnoteExcerpt,
        gaDataLayerConfig,
        itemTitle: title,
        wordmark,
        parentType
    }: GenericChildContentProps & { parentType?: any },
    index?: number
) => {
    const { Link } = entriesByTypename(items);
    const ctaGroup =
        Link && ctaGroupAdapter(colorTheme, gaDataLayerConfig, Link);
    const ctaLimit = 1;
    const shouldRenderLinksAsList = Link && Link.length > ctaLimit;
    const theme: CSSProperties = {
        color: colorTheme?.containerText
    };
    const titleTheme: CSSProperties = {
        color: colorTheme?.titleText
    };
    const footerTheme: CSSProperties = {
        color: colorTheme?.legalText
    };
    const displayTitle = title && interpolate(title as string, tokenStrings);
    const isFootnoteAvailable = footnote?.json || footnoteExcerpt?.json;

    const type = parentType.toLowerCase();

    const linkItem: ListItem = (
        { gaDataLayerConfig, label = '', url }: LinkProps,
        i
    ) => {
        const pagePath = useRouter().asPath;
        const handleClick = () => {
            setModuleAmplitudeEvent({ gaDataLayerConfig, label }, pagePath);
        };

        return label ? (
            <SafeAnchorWithGaEvent
                {...{
                    gaDataLayerConfig
                }}
                href={url}
                key={i}
                onClick={handleClick}
            >
                {label}
            </SafeAnchorWithGaEvent>
        ) : (
            <></>
        );
    };

    return (
        <article key={index} style={theme}>
            {backgroundImage && (
                <ConditionalLink
                    gaDataLayerConfig={gaDataLayerConfig}
                    href={contentLink?.url}
                >
                    <ResponsiveImage {...backgroundImage} />
                </ConditionalLink>
            )}
            <div>
                {wordmark && (
                    <ResponsiveImage
                        className={styles.wordmark}
                        {...wordmark}
                    />
                )}
                {displayTitle && (
                    <Heading tag="h3" style={titleTheme}>
                        <ConditionalLink
                            gaDataLayerConfig={gaDataLayerConfig}
                            href={contentLink?.url}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: displayTitle
                                }}
                            />
                        </ConditionalLink>
                    </Heading>
                )}

                {description && (
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                )}

                {/* CTA Group */}
                {ctaGroup && (
                    <FlexGridLayout
                        align={type === 'bucket' ? 'stretch' : 'start'}
                        className={classnames(
                            styles['module-nav'],
                            styles[`module-nav--${type}`],
                            {
                                [styles.row]: shouldRenderLinksAsList
                            }
                        )}
                        direction={shouldRenderLinksAsList ? 'row' : 'col'}
                        justify={type === 'bucket' ? 'center' : 'start'}
                        wrap={type === 'tile'}
                    >
                        {shouldRenderLinksAsList ? (
                            <ItemList item={linkItem} itemProps={items} />
                        ) : (
                            <BrandedCtaGroup
                                ctaGroup={ctaGroup.slice(0, ctaLimit)}
                            />
                        )}
                    </FlexGridLayout>
                )}
            </div>

            {isFootnoteAvailable && (
                <ExcerptFooter
                    {...{ footnote, footnoteExcerpt, footerTheme }}
                />
            )}
        </article>
    );
};

export default ModuleItem;
