import { ReactNode } from 'react';

import Banner, { BannerProps } from '@tb-core/components/composites/banner';
import WebpageContent from '@tb-core/components/container/layout/webpage-content';
import ResponsiveMedia, {
    ResponsiveMediaProps
} from '@tb-core/components/simple/responsive-media';

export interface HeroProps extends BannerProps {
    backgroundImage?: ResponsiveMediaProps;
    body?: ReactNode;
    footer?: ReactNode;
}

const Hero = ({ backgroundImage, body, footer, ...bannerProps }: HeroProps) => (
    <>
        {(backgroundImage || body) && (
            <Banner {...bannerProps}>
                <WebpageContent
                    backgroundImage={
                        backgroundImage && (
                            <ResponsiveMedia
                                mobileContain
                                {...backgroundImage}
                            />
                        )
                    }
                    body={body}
                    footer={footer}
                />
            </Banner>
        )}
    </>
);

export default Hero;
