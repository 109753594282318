import { useEffect, useState } from 'react';
import { useTimer } from '../use-timer';

interface WindowSize {
    height?: number;
    width?: number;
}

// @TODO: refactor timeout to https://gist.githubusercontent.com/jamesfulford/de7b5e5f37d97595e0626cca6f2002e0/raw/f5fda29af248607dd2d44c2b18e094ace1a2d065/useTimeout.ts

/**
 * Returns the current height, width of the browser onResize, throttled by delay
 * @example
 *      const { height, width } = useWindowResize(300);
 */
const useWindowResize = (delay: number = 300) => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        height: undefined,
        width: undefined
    });

    const [, dispatch] = useTimer({ timeout: delay });

    const handleResize = () =>
        setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth
        });

    const onResize = () => {
        dispatch(handleResize);
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        handleResize();

        return () => window.removeEventListener('resize', onResize);
    }, []);

    return windowSize;
};

export default useWindowResize;
