import { ReactNode } from 'react';

interface WebpageContentProps {
    backgroundImage?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;
    header?: ReactNode;
}

const WebpageContent = ({
    backgroundImage,
    body,
    footer,
    header
}: WebpageContentProps) => (
    <>
        {backgroundImage}
        {header && <header>{header}</header>}
        {body && <section>{body}</section>}
        {footer && <footer>{footer}</footer>}
    </>
);

export default WebpageContent;
