import React from 'react';

import { EmbeddedVideoProps } from '@tb-core/components/simple/embedded-video';
import useWindowResize from '@tb-core/hooks/window/use-window-resize';

export interface ResponsiveVideoProps
    extends Omit<EmbeddedVideoProps, 'source'> {
    Video: (props: EmbeddedVideoProps) => JSX.Element;
    breakpoint: number;
    delay?: number;
    mobileUrl?: string;
    url: string;
}

const ResponsiveVideo = ({
    Video,
    breakpoint,
    delay,
    mobileUrl,
    url,
    ...props
}: ResponsiveVideoProps) => {
    const { width } = useWindowResize(delay);
    let source = url;

    if (mobileUrl) {
        source = width && width > breakpoint ? url : mobileUrl;
    }

    return <Video {...props} source={source} />;
};

export default ResponsiveVideo;
