import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GenericChildContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface StandardTextItemContentProps extends GenericChildContentProps {
    className?: string;
}

const StandardTextItem = ({
    className,
    colorTheme,
    description,
    entriesCollection: { items },
    itemTitle: title,
    subtitle,
    type,
    footnote
}: StandardTextItemContentProps) => {
    const { Link } = entriesByTypename(items);

    const dataLayerTemplate = {
        'Analytics-Action': type,
        'Analytics-Value': title
    };
    const ctaGroup = ctaGroupAdapter(colorTheme, dataLayerTemplate, Link);
    const ctaLimit = 2;
    const titleTheme = {
        color: colorTheme?.titleText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };
    const isGraphicCta =
        typeof (ctaGroup && ctaGroup[0] && ctaGroup[0].graphic) !== 'undefined';

    const displayTitle = title && interpolate(title, tokenStrings);
    const displaySubtitle = subtitle && interpolate(subtitle, tokenStrings);

    return (
        <div className={classNames(styles['standard-text-item'], className)}>
            {displayTitle && (
                <Heading
                    dangerouslySetInnerHTML={{ __html: displayTitle }}
                    style={titleTheme}
                    tag="h3"
                ></Heading>
            )}

            {displaySubtitle && (
                <strong
                    dangerouslySetInnerHTML={{
                        __html: displaySubtitle
                    }}
                />
            )}

            {/* Description Copy as subheadine */}
            {description?.json && (
                <div>
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                </div>
            )}

            {ctaGroup && (
                <FlexGridLayout
                    align="stretch"
                    className={classNames(styles['standard-text-nav'], {
                        [styles['nav-graphic']]: isGraphicCta
                    })}
                    justify="center"
                >
                    <BrandedCtaGroup ctaGroup={ctaGroup.slice(0, ctaLimit)} />
                </FlexGridLayout>
            )}

            {footnote?.json && (
                <footer style={footerTheme}>
                    <ContentfulRichTextWithTheme
                        node={footnote.json}
                        renderOptions={{ renderText }}
                    />
                </footer>
            )}
        </div>
    );
};
export default StandardTextItem;
