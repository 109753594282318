import classNames from 'classnames';
import React from 'react';

import EmbeddedVideo, {
    EmbeddedVideoProps
} from '@tb-core/components/simple/embedded-video';

import styles from './styles.module.scss';

export type BackgroundCoverVideoProps = EmbeddedVideoProps;

const BackgroundCoverVideo = ({
    className,
    ...props
}: BackgroundCoverVideoProps) => (
    <EmbeddedVideo
        {...props}
        autoPlay
        className={classNames(styles.video, className)}
        key={props.source}
        loop
        muted
        playsInline
    />
);

export default BackgroundCoverVideo;
