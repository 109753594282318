import { useEffect, useRef } from 'react';

type Args = any[];

/**
 * Returns the current height, width of the browser onResize, throttled by delay
 * @example
 *      const debouncedHandler = useDebounced(handler, 500);
 */
export const useDebounced = (
    callback: (...args: Args) => void,
    wait: number = 300
) => {
    const callbackArgsRef = useRef<Args>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const clearTimer = () => timeout.current && clearTimeout(timeout.current);

    useEffect(() => clearTimer, []);

    const debouncedCallback = (...args: Args) => {
        callbackArgsRef.current = args;
        clearTimer();

        timeout.current = setTimeout(() => {
            if (callbackArgsRef.current) {
                callback(...callbackArgsRef.current);
            }
        }, wait);
    };

    return debouncedCallback;
};
