import {
    OptimizelyExperiment,
    OptimizelyVariation
} from '@optimizely/react-sdk';
import { CSSProperties, KeyboardEvent, useState } from 'react';

import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import InfoBox from '@tb-core/components/styled/info-box';
import { optimizelyClient } from '@tb-core/helpers/analytics/optimizely';
import keyDownHandler from '@tb-core/helpers/form/handle-key-down';
import { useOptimizelyFlag } from '@tb-core/hooks/analytics/use-optimizely-flag';
import usePageContext from '@tb-core/hooks/use-page-context';
import { GenericContentType } from '@tb-core/types';
import { ContentfulRichTextDocument } from '@tb-core/types/contentful';

import styles from './styles.module.scss';

interface ExcerptFooterProps {
    footnote?: ContentfulRichTextDocument;
    footnoteExcerpt?: ContentfulRichTextDocument;
    footerTheme?: CSSProperties;
    type?: GenericContentType;
}

const ExcerptFooter = ({
    footnote,
    footnoteExcerpt,
    footerTheme,
    type
}: ExcerptFooterProps) => {
    const [toggleOpen, setToggleOpen] = useState(false);

    const {
        topicMeta: { generic }
    } = usePageContext().content.pageData;
    const label = generic?.termLabels?.web.footnote_label || '';
    const legalTestEnabled = useOptimizelyFlag(
        'discover_homepage_legal_tool_tip_test'
    );
    const handleOpen = () => {
        setToggleOpen(true);
        if (legalTestEnabled) {
            optimizelyClient.track('clicked_see_more_legal_tooltips');
        }
    };
    const handleClose = () => setToggleOpen(false);

    const onKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Tab') {
            return;
        }
        e.preventDefault();
        keyDownHandler({
            event: e,
            // on spacebar event open infobox
            spacebarEvent: () => setToggleOpen(!toggleOpen)
        });
    };

    const footnotesAccessible = footnote?.json && footnoteExcerpt?.json;

    const DefaultContent = () => (
        <div className={styles['excerpt-container']}>
            {footnoteExcerpt?.json && (
                <ContentfulRichTextWithTheme
                    node={footnoteExcerpt.json}
                    renderOptions={{
                        renderText
                    }}
                />
            )}{' '}
            {footnote?.json && (
                <ContentfulRichTextWithTheme
                    node={footnote.json}
                    renderOptions={{ renderText }}
                />
            )}
        </div>
    );
    const ExcerptContent = () => (
        <div className={styles['excerpt-container']}>
            {footnote?.json && (
                <InfoBox
                    {...{
                        handleClose,
                        toggleOpen
                    }}
                    content={
                        <ContentfulRichTextWithTheme
                            node={footnote.json}
                            renderOptions={{
                                renderText
                            }}
                        />
                    }
                    infoBoxClassName={`${type?.toLowerCase()}-infobox`}
                />
            )}
            {footnoteExcerpt?.json && (
                <ContentfulRichTextWithTheme
                    node={footnoteExcerpt.json}
                    renderOptions={{
                        renderText
                    }}
                />
            )}{' '}
            {footnotesAccessible && (
                <button
                    aria-label={label}
                    className={styles['excerpt-button']}
                    onClick={handleOpen}
                    onKeyDown={onKeyDown}
                    tabIndex={0}
                >
                    {label}
                </button>
            )}
        </div>
    );

    return (
        <footer className={styles['excerpt-footer']} style={footerTheme}>
            {legalTestEnabled ? (
                <OptimizelyExperiment experiment="legal_tool_tip_test">
                    <OptimizelyVariation variation="legal_infobox_enablement">
                        {footnotesAccessible ? (
                            <ExcerptContent />
                        ) : (
                            <DefaultContent />
                        )}
                    </OptimizelyVariation>
                    <OptimizelyVariation variation="legal_default_full_excerpt">
                        <DefaultContent />
                    </OptimizelyVariation>
                </OptimizelyExperiment>
            ) : (
                <DefaultContent />
            )}
        </footer>
    );
};

export default ExcerptFooter;
