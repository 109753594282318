import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ItemList from '@tb-core/components/simple/item-list';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import {
    childItemAdapter,
    ctaGroupAdapter
} from '@tb-core/components/styled/modules/helpers';
import ModuleItem from '@tb-core/components/styled/modules/module-item';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import usePageContext from '@tb-core/hooks/use-page-context';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

/**
 * TODO: https://tacobeta.atlassian.net/browse/ECDISC-565
 * Shared module layout component for content modules, Banner, Tile and Bucket
 *
 */
const ModuleContent = ({
    className,
    colorTheme,
    description,
    entriesCollection,
    horizontalPosition = 'Left',
    footnote,
    title,
    type
}: WebpageContentProps) => {
    /* Extract Link and WebpageContent types from entriesCollection items */
    const { Link, WebpageContent } = entriesByTypename(entriesCollection.items);
    const {
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;
    const dataLayerTemplate = {
        'Analytics-Action': type,
        'Analytics-Value': title
    };

    const items = childItemAdapter({
        colorTheme,
        dataLayerTemplate,
        daypartsConfig,
        moduleContent: WebpageContent,
        moduleProps: {
            parentType: type
        }
    });
    const ctaGroup = ctaGroupAdapter(colorTheme, dataLayerTemplate, Link);

    const titleTheme = {
        color: colorTheme?.titleText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };
    const hasGraphicCta =
        ctaGroup && ctaGroup.some(cta => cta.graphic !== null);

    const contentTitle = title && interpolate(title, tokenStrings);

    return (
        <div className={className}>
            {contentTitle && (
                <Heading
                    dangerouslySetInnerHTML={{ __html: contentTitle }}
                    style={titleTheme}
                    tag="h2"
                />
            )}
            {description && description?.json && (
                <div>
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                </div>
            )}
            {ctaGroup && (
                <FlexGridLayout
                    align="stretch"
                    className={classNames(styles['module-content-nav'], {
                        [styles.center]: horizontalPosition === 'Center',
                        [styles['nav-graphic']]: hasGraphicCta,
                        [styles.single]: ctaGroup.length < 2
                    })}
                    justify="center"
                >
                    <BrandedCtaGroup ctaGroup={ctaGroup.slice(0, 2)} />
                </FlexGridLayout>
            )}
            {items && (
                <FlexGridLayout
                    align="stretch"
                    className={classNames(
                        styles['module-items'],
                        styles[`${type?.toLowerCase()}`]
                    )}
                    justify="center"
                    wrap={true}
                >
                    <ItemList item={ModuleItem} itemProps={items} />
                </FlexGridLayout>
            )}
            {footnote && footnote?.json && (
                <footer style={footerTheme}>
                    <ContentfulRichTextWithTheme
                        node={footnote.json}
                        renderOptions={{ renderText }}
                    />
                </footer>
            )}
        </div>
    );
};

export default ModuleContent;
