import classNames from 'classnames';
import { ReactNode, useRef } from 'react';

import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import Button from '@tb-core/components/styled/buttons/button';
import useOnClickOutside from '@tb-core/hooks/use-on-click-outside';

import styles from './styles.module.scss';

interface InfoBoxProps {
    content: ReactNode | string;
    handleClose: () => void;
    infoBoxClassName?: string;
    label?: string;
    toggleOpen: boolean;
}

const InfoBox = ({
    content,
    handleClose,
    infoBoxClassName,
    toggleOpen
}: InfoBoxProps) => {
    const currentRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(currentRef, handleClose);

    return (
        <div
            className={classNames(
                styles.infobox,
                styles[`${infoBoxClassName}`]
            )}
            ref={currentRef}
        >
            <div
                className={classNames(styles['infobox-container'], {
                    [styles.show]: toggleOpen
                })}
            >
                <Button
                    aria-label="Close"
                    autoFocus={true}
                    className={styles.close}
                    onClick={handleClose}
                >
                    <LazyIconClose size="1em" />
                </Button>
                {content}
                <div className={styles.caret}></div>
            </div>
        </div>
    );
};

export default InfoBox;
