import { RealObject } from '@tb-core/types';

export interface ModuleAnalyticsAdapterProps {
    data: RealObject[];
    dataLayerTemplate: RealObject;
}

/**
 * Takes the collection of items and merges in the dataLayer object
 * @returns mutated array of data
 *
 */
export const moduleAnalyticsAdapter = ({
    data,
    dataLayerTemplate
}: ModuleAnalyticsAdapterProps) => {
    if (!data) {
        return data;
    }
    let i = 0;
    const limit = data.length;

    while (i < limit) {
        const dataLayer = {
            ...dataLayerTemplate
        };
        let value;

        // if Link type, asign Label as Action-Value
        if (
            data[i].__typename &&
            data[i].__typename === 'Link' &&
            data[i].label
        ) {
            value = data[i].label;
        } else {
            value = data[i].title || data[i].itemTitle;
        }

        dataLayer['Analytics-Value'] =
            dataLayerTemplate &&
            dataLayerTemplate['Analytics-Value']?.length > 0
                ? dataLayerTemplate['Analytics-Value'] + ' - ' + value
                : value;

        dataLayer['Analytics-Action'] =
            dataLayerTemplate &&
            dataLayerTemplate['Analytics-Action']?.length > 0 &&
            dataLayerTemplate['Analytics-Action'];

        // Override any gaDataLayerConfig passed in from Contentful
        data[i].gaDataLayerConfig = {
            ...dataLayer
        };

        i++;
    }
    return data;
};
