import { Options, RenderText } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import SafeAnchor from '@tb-core/components/behavior/safe-anchor';
import ContentfulRichText, {
    ContentfulRichTextProps
} from '@tb-core/components/simple/contentful-rich-text';
import { HyperLinkProps } from '@tb-core/components/simple/contentful-rich-text-with-ga-event';
import Heading from '@tb-core/components/simple/heading';
import Image from '@tb-core/components/styled/image';
import { ContentfulRichLinkProps } from '@tb-core/helpers/adapters/contentful/rich-text-props-adapter';
import mapLinkedEntries from '@tb-core/helpers/contentful/map-linked-entries';
import { ColorThemeProps, JsxChildren, RealObject } from '@tb-core/types';

const toID = (children: React.ReactNode) => {
    let arr;
    if (Array.isArray(children)) {
        arr = children.flat().map(ele => {
            return typeof ele === 'string' ? ele : '_';
        });
    }
    const str = String(arr || children);

    return slugify(str);
};

export const stripTags = (str: string) => str.replace(/(<([^>]+)>)/gi, '');

export const slugify = (val: string) => {
    // trim all leading/trailing slashes and spaces
    let str = val.replace(/^[\s+\/]+|[\s+\/]+$/g, '').toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
};

interface ContentfulRichTextWithThemeProps extends ContentfulRichTextProps {
    colorTheme?: ColorThemeProps | null;
    linkedEntries?: ContentfulRichLinkProps;
}

/**
 * locate tokens wrapped in currly braces
 * and replace the matching key in `tokenNodes` object
 * ex: {copy} will be replaced with <sup>&copy;</sup>
 */
const renderRichTextMarks: RenderText = text =>
    text
        .split(/({[^{}]*})/g)
        .reduce(
            (children: any, textSegment: string) => [
                ...children,
                tokenNodes[textSegment] || textSegment
            ],
            []
        );

/**
 * tokens as react nodes
 * non-exported memnber
 * used by the above `renderRichText` fn which is pass as
 * the `renderText` option of ContentfulRichText component
 */
const tokenNodes: RealObject = {
    '{1}': <sup>1</sup>,
    '{2}': <sup>2</sup>,
    '{3}': <sup>3</sup>,
    '{4}': <sup>4</sup>,
    '{5}': <sup>5</sup>,
    '{6}': <sup>6</sup>,
    '{7}': <sup>7</sup>,
    '{8}': <sup>8</sup>,
    '{9}': <sup>9</sup>,
    '{asterisk}': <sup>&#42;</sup>,
    '{copysub}': <sub>&copy;</sub>,
    '{copy}': <sup>&copy;</sup>,
    '{dagger}': <sup>&dagger;</sup>,
    '{delta}': <sup>&Delta;</sup>,
    '{doubledagger}': <sup>&Dagger;</sup>,
    '{downarrow}': <sup>&darr;</sup>,
    '{lozenge}': <sup>&#9674;</sup>,
    '{numbersign}': <sup>&#35;</sup>,
    '{pilcrow}': <sup>&#8267;</sup>,
    '{regsub}': <sub>&reg;</sub>,
    '{reg}': <sup>&reg;</sup>,
    '{section}': <sup>&sect;</sup>,
    '{tradesub}': <sub>&trade;</sub>,
    '{trade}': <sup>&trade;</sup>,
    '{vertical}': <sup>&#8214;</sup>
};

/**
 * tokens for use with CTF strings values
 * exported
 * result intended to be sent to `dangerouslySetInnerHtml`
 */
const tokenStrings: RealObject = {
    1: '<sup>1</sup>',
    2: '<sup>2</sup>',
    3: '<sup>3</sup>',
    4: '<sup>4</sup>',
    5: '<sup>5</sup>',
    6: '<sup>6</sup>',
    7: '<sup>7</sup>',
    8: '<sup>8</sup>',
    9: '<sup>9</sup>',
    asterisk: <sup>&#42;</sup>,
    copy: '<sup>&copy;</sup>',
    copysub: '<sub>&copy;</sub>',
    dagger: '<sup>&dagger;</sup>',
    delta: '<sup>&Delta;</sup>',
    doubledagger: '<sup>&Dagger;</sup>',
    downarrow: '<sup>&darr;</sup>',
    lozenge: '<sup>&#9674;</sup>',
    numbersign: '<sup>&#35;</sup>',
    pilcrow: '<sup>&#8267;</sup>',
    reg: '<sup>&reg;</sup>',
    regsub: '<sub>&reg;</sub>',
    section: '<sup>&sect;</sup>',
    trade: '<sup>&trade;</sup>',
    tradesub: '<sub>&trade;</sub>',
    vertical: '<sup>&#8214;</sup>'
};

const ContentfulRichTextWithTheme = ({
    linkedEntries,
    node,
    colorTheme,
    renderOptions
}: ContentfulRichTextWithThemeProps) => {
    const webLinkTheme = {
        color: colorTheme?.webLink
    };
    let linkedEntryMap: Record<string, any>;
    const options: Options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (_, children) => (
                <Heading tag="h2" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_3]: (_, children) => (
                <Heading tag="h3" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_4]: (_, children) => (
                <Heading tag="h4" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_5]: (_, children) => (
                <Heading tag="h5" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_6]: (_, children) => (
                <Heading tag="h6" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [INLINES.HYPERLINK]: ({
                data,
                content
            }: HyperLinkProps): JsxChildren => {
                const { uri } = data;
                const { value = '' } = content[0];
                return (
                    <SafeAnchor href={uri} style={webLinkTheme}>
                        {value}
                    </SafeAnchor>
                );
            },
            [INLINES.EMBEDDED_ENTRY]: node => {
                linkedEntryMap =
                    linkedEntryMap || mapLinkedEntries(linkedEntries);
                // find the entry in the entryMap by ID
                const entry =
                    linkedEntryMap.entryMap.get(node.data.target.sys.id) || {};

                if (entry.__typename === 'Image') {
                    return <Image {...entry} />;
                }
            }
        }
    };

    if (renderOptions?.renderText) {
        options.renderText = renderOptions.renderText;
    }

    return <ContentfulRichText node={node} renderOptions={options} />;
};

export default ContentfulRichTextWithTheme;
export { tokenStrings, renderRichTextMarks };
