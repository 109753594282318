import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import Scrollable from '@tb-core/components/composites/scrollable';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { isWholeNumber } from '@tb-core/helpers/utils/is-whole-number';
import useLayoutEffectIsomorphic from '@tb-core/hooks/use-layout-effect-isomorphic';
import useMatchMedia from '@tb-core/hooks/window/use-matchmedia';
import useScrollPosition from '@tb-core/hooks/window/use-scroll-position';
import { CarouselConfigProps, RealObject } from '@tb-core/types';
import ScrollableControls from './scrollable-carousel-controls';

import styles from './styles.module.scss';

export interface ScrollableCarouselProps extends CarouselConfigProps {
    className?: string;
    slide: ListItem;
    slideProps: RealObject[];
}

/**
 * Passes slide and slideProps down to an ItemList component...
 * ...and wraps thing in a scrollable component with nav dots.
 *
 * @example
 *   <ScrollableCarousel
 *       className={styles['scrollable-buckets']}
 *       slide={StandardCarouselItem}
 *       slideProps={bucketItems}
 *       slidesToShowDesktop={3}
 *       slidesToShowMobile={1}
 *   />
 */
const ScrollableCarousel = ({
    carouselClassName,
    className,
    slide,
    slideProps,
    slidesToShowDesktop = 3,
    slidesToShowMobile = 1
}: ScrollableCarouselProps) => {
    const carouselRef = useRef<any>();
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [slidesPerPage, setSlidesPerPage] = useState<number>(1);
    const { scrollX } = useScrollPosition(carouselRef, 350);
    const isMobile = useMatchMedia('(max-width: 640px)');
    const colorTheme = slideProps[0]?.colorTheme || {};

    const findCurrentByScroll = (xPosition: number = 0) => {
        const slides = carouselRef.current.children;
        let n = 0;

        for (let i = 1; i < slides.length; i++) {
            const diffI = Math.abs(slides[i].offsetLeft - xPosition);
            const diffN = Math.abs(slides[n].offsetLeft - xPosition);
            // set `n` to the smaller number
            n = diffI < diffN ? i : n;
            // if one of the offsets is 0, break
            if (diffI === 0 || diffN === 0) {
                break;
            }
        }

        return n;
    };

    /* place slide in center on mobile screens */
    const getScrollOffset = () => {
        if (!isMobile) {
            return 0;
        }

        const clientWidth = carouselRef.current?.clientWidth;
        const slideWidth =
            carouselRef.current?.children[currentSlide].clientWidth;

        return Math.round((clientWidth - slideWidth) / 2);
    };

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    /* respond to slide changes */
    useLayoutEffectIsomorphic(() => {
        const left = carouselRef.current?.children[currentSlide]?.offsetLeft;
        const offset = getScrollOffset();
        carouselRef.current?.scrollTo({
            behavior: 'smooth',
            left: (left || 0) - offset
        });
    }, [currentSlide]);

    /* respond to mediaQuery changes */
    useLayoutEffectIsomorphic(() => {
        const count = isMobile ? slidesToShowMobile : slidesToShowDesktop;
        setSlidesPerPage(count);
    }, [isMobile]);

    /* respond to scroll changes */
    useLayoutEffectIsomorphic(() => {
        const scrollIndex = findCurrentByScroll(scrollX);

        if (isWholeNumber(scrollIndex, slidesPerPage)) {
            onNavClick(Math.round(scrollIndex / slidesPerPage));
        }
    }, [scrollX]);

    const onNavClick = (n: number) => {
        setCurrentSlide(n * slidesPerPage);
    };

    return (
        <>
            <Scrollable
                className={classNames(
                    styles['scrollable-carousel'],
                    carouselClassName,
                    className
                )}
                ref={carouselRef}
            >
                <ItemList item={slide} itemProps={[...slideProps]} />
            </Scrollable>
            {slideProps.length > slidesPerPage && (
                <ScrollableControls
                    colorTheme={colorTheme}
                    active={Math.floor(currentSlide / slidesPerPage)}
                    classNameActive={styles['scrollable-nav-button-active']}
                    className={styles['scrollable-nav-button']}
                    classNameContainer={styles['scrollable-controls']}
                    handler={onNavClick}
                    length={Math.ceil(slideProps.length / slidesPerPage)}
                />
            )}
        </>
    );
};

export default ScrollableCarousel;
