import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import EmbeddedVideo from '@tb-core/components/simple/embedded-video';
import Heading from '@tb-core/components/simple/heading';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import TwitchEmbed from '@tb-core/components/styled/twitch-embed';
import TwitchEmbedEverything from '@tb-core/components/styled/twitch-embed-everything';
import YouTubeVideo from '@tb-core/components/styled/youtube-video';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface StandardVideoContentProps extends WebpageContentProps {
    className?: string;
}

const StandardVideoContent = ({
    className,
    colorTheme,
    description,
    entriesCollection: { items },
    footnote,
    subtitle,
    title,
    type
}: StandardVideoContentProps) => {
    const {
        Link,
        VideoPlayer,
        VideoPlayer: [{ video }]
    } = entriesByTypename(items);

    const dataLayerTemplate = {
        'Analytics-Action': type,
        'Analytics-Value': title
    };
    const ctaGroup = ctaGroupAdapter(colorTheme, dataLayerTemplate, Link);
    const ctaLimit = 2;
    const titleTheme = { color: colorTheme?.titleText };
    const footerTheme = { color: colorTheme?.legalText };
    const isGraphicCta =
        typeof (ctaGroup && ctaGroup[0] && ctaGroup[0].graphic) !== 'undefined';
    const displayTitle = title && interpolate(title, tokenStrings);
    const displaySubtitle = subtitle && interpolate(subtitle, tokenStrings);

    return (
        <div className={className}>
            {displayTitle && (
                <Heading
                    dangerouslySetInnerHTML={{ __html: displayTitle }}
                    style={titleTheme}
                    tag="h2"
                />
            )}

            {displaySubtitle && (
                <strong
                    dangerouslySetInnerHTML={{
                        __html: displaySubtitle
                    }}
                />
            )}

            {description?.json && (
                <ContentfulRichTextWithTheme
                    colorTheme={colorTheme}
                    node={description.json}
                    renderOptions={{ renderText }}
                />
            )}

            {video?.__typename.toLowerCase() === 'youtubevideo' && (
                <YouTubeVideo
                    className={classNames(styles['standard-video-container'])}
                    containerClassName={classNames(
                        styles['standard-video-yt-container']
                    )}
                    includeChat={video?.includeChat}
                    videoId={video?.videoId}
                />
            )}

            {video?.__typename.toLowerCase() === 'embeddedvideo' && (
                <EmbeddedVideo
                    className={classNames(styles['standard-video-container'])}
                    controls={VideoPlayer[0]?.enableControls}
                    mediaType="mp4"
                    source={video?.url}
                />
            )}

            {video?.__typename.toLowerCase() === 'twitchembed' && (
                <>
                    {video.embedEverything ? (
                        <TwitchEmbedEverything
                            {...video}
                            className={classNames(
                                styles['standard-video-container']
                            )}
                        />
                    ) : (
                        <TwitchEmbed
                            {...video}
                            className={classNames(
                                styles['standard-video-container']
                            )}
                        />
                    )}
                </>
            )}

            {ctaGroup && (
                <FlexGridLayout
                    align="stretch"
                    className={classNames(styles['standard-video-cta-group'], {
                        [styles['nav-graphic']]: isGraphicCta
                    })}
                    justify="center"
                >
                    <BrandedCtaGroup ctaGroup={ctaGroup.slice(0, ctaLimit)} />
                </FlexGridLayout>
            )}

            {footnote?.json && (
                <footer
                    className={classNames(styles['standard-video-footer'])}
                    style={footerTheme}
                >
                    <ContentfulRichTextWithTheme
                        node={footnote.json}
                        renderOptions={{ renderText }}
                    />
                </footer>
            )}
        </div>
    );
};
export default StandardVideoContent;
