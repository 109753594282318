import { ThemableLinkButtonProps } from '@tb-core/components/styled/links/themable-link-button';
import { ColorThemeProps, RealObject } from '@tb-core/types';
import { mergeThemeIntoCtagroup, moduleAnalyticsAdapter } from './';

/**
 * Given a ctaGroup, call the theme and analytics adapters
 */
export const ctaGroupAdapter = (
    colorTheme: ColorThemeProps | null | undefined,
    dataLayerTemplate: RealObject | undefined,
    items: ThemableLinkButtonProps[]
) => {
    if (!items || items.length < 1) {
        return items;
    }
    let result = items;

    if (colorTheme) {
        result = mergeThemeIntoCtagroup(
            result,
            colorTheme
        ) as ThemableLinkButtonProps[];
    }

    if (dataLayerTemplate) {
        result = moduleAnalyticsAdapter({
            data: result,
            dataLayerTemplate
        }) as ThemableLinkButtonProps[];
    }

    return result;
};
