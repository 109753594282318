import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import { GenericModuleProps } from '@tb-core/types';
import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

type StandardHeroFooterProps = DivProps &
    Pick<GenericModuleProps, 'footnote' | 'footnoteExcerpt' | 'colorTheme'>;

const StandardHeroFooter = ({
    footnote,
    footnoteExcerpt,
    colorTheme
}: StandardHeroFooterProps) => {
    const footerTheme = {
        ...{
            background: colorTheme?.legalBackground || 'rgba(0,0,0,0.35)'
        },
        ...{ color: colorTheme?.legalText || '#fff' }
    };
    const footerJson = footnoteExcerpt?.json || footnote?.json;

    if (!footerJson) {
        return null;
    }

    return (
        <div className={styles.footer} style={footerTheme}>
            <ContentfulRichTextWithTheme
                node={footerJson}
                renderOptions={{ renderText }}
            />
        </div>
    );
};

export default StandardHeroFooter;
