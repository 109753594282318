import { ColorThemeProps, RealObject } from '@tb-core/types';

export const applyThemeToEntries = (
    entryCollection: RealObject[],
    themeObj: ColorThemeProps
) => {
    let i = 0;
    const limit = entryCollection.length;

    while (i < limit) {
        if (!entryCollection[i].colorTheme) {
            entryCollection[i].colorTheme = themeObj;
        }
        i++;
    }
    return entryCollection;
};
