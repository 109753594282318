import classNames from 'classnames';
import { useRouter } from 'next/router';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { SafeAnchorWithGaEventProps } from '@tb-core/components/behavior/safe-anchor/with-ga-event';

import styles from './styles.module.scss';

interface FaqContentLinkProps extends SafeAnchorWithGaEventProps {
    className?: string;
}

const FaqContentLink = ({
    className,
    gaDataLayerConfig,
    href,
    label
}: FaqContentLinkProps) => {
    const { asPath } = useRouter();

    if (!asPath.includes('/faqs')) {
        return null;
    }

    return (
        <div className={styles['content-link-container']}>
            <SafeAnchorWithGaEvent
                className={classNames(className, styles['content-link'])}
                gaDataLayerConfig={gaDataLayerConfig}
                href={href}
            >
                {label}
            </SafeAnchorWithGaEvent>
        </div>
    );
};

export default FaqContentLink;
