import DaypartAdapter from '@tb-core/adapters/contentful/daypart/daypart-adapter';
import { currentTimeIsBetween } from '@tb-core/helpers/browser/datetime';
import { moveItemInArray } from '@tb-core/helpers/utils/list-transform';
import { RealObject } from '@tb-core/types';
import { ContentfulProductCategory } from '@tb-core/types/contentful/products';

type DayPart = null | string;
type DayPartConfig = RealObject;

export interface MoveDaypartConfig {
    daypart: string;
    position: number;
    products: ContentfulProductCategory[];
}

export const getDaypartTimeRange = (daypart: DayPart, config?: DayPartConfig) =>
    config && daypart !== null ? config[daypart] : {};

export const isDayPartNow = (daypart: DayPart, config?: DayPartConfig) => {
    const { start_time, end_time } = getDaypartTimeRange(
        DaypartAdapter(daypart),
        config
    );

    return config && currentTimeIsBetween(start_time, end_time);
};

export const moveDaypartPosition = (config: MoveDaypartConfig) => {
    const daypartIndex = config.products.findIndex(
        category => category?.label?.toLowerCase() === config.daypart
    );

    return moveItemInArray(config.products, daypartIndex, config.position);
};
