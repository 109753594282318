import classNames from 'classnames';

import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';
import StandardModule from '@tb-core/components/container/modules/standard-module';
import { getThemeFont } from '@tb-core/components/styled/modules/helpers/get-theme-fonts';
import { SlideClass } from '@tb-core/components/styled/modules/standard-carousel/standard-carousel-item';
import { GenericModuleProps } from '@tb-core/types';
import ScrollableCarouselContent from './standard-carousel-content';

import styles from './styles.module.scss';

export const carouselConfigDefaults = {
    slidesToShowDesktop: 3,
    slidesToShowMobile: 1
};

const StandardCarousel = ({
    backgroundImage,
    carouselConfig = carouselConfigDefaults,
    className,
    colorTheme,
    fragmentIdentifier,
    style,
    ...props
}: GenericModuleProps) => {
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        color: colorTheme?.containerText,
        ...style
    };

    const themeFontClassName = getThemeFont(colorTheme?.font);

    return (
        <StandardModule
            backgroundImage={backgroundImage as ResponsiveImageProps}
            body={
                <ScrollableCarouselContent
                    {...{
                        ...props,
                        carouselConfig,
                        colorTheme
                    }}
                />
            }
            className={classNames(
                styles['standard-carousel'],
                {
                    [styles[
                        SlideClass[carouselConfig.slidesToShowDesktop as number]
                    ]]: carouselConfig.slidesToShowDesktop
                },
                themeFontClassName,
                className
            )}
            fragmentIdentifier={fragmentIdentifier}
            style={theme}
        />
    );
};

export default StandardCarousel;
