import { ReactNode } from 'react';

import ResponsiveImage, {
    ResponsiveImageProps
} from '@tb-core/components/composites/responsive-image';
import WebpageContent from '@tb-core/components/container/layout/webpage-content';
import { DivProps } from '@tb-core/types/elements/standard';

export interface StandardModuleProps extends DivProps {
    backgroundImage?: ResponsiveImageProps;
    body?: ReactNode;
    fragmentIdentifier?: string;
}

const StandardModule = ({
    backgroundImage,
    body,
    fragmentIdentifier,
    ...moduleProps
}: StandardModuleProps) => (
    <>
        {(backgroundImage || body) && (
            <div {...moduleProps}>
                {fragmentIdentifier && <a id={fragmentIdentifier}></a>}
                <WebpageContent
                    backgroundImage={
                        backgroundImage && (
                            <ResponsiveImage {...backgroundImage} />
                        )
                    }
                    body={body}
                />
            </div>
        )}
    </>
);

export default StandardModule;
