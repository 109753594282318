import classNames from 'classnames';

import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';
import StandardModule from '@tb-core/components/container/modules/standard-module';
import StandardVideoContent from '@tb-core/components/styled/modules/standard-video/standard-video-content';
import { GenericModuleProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardVideo = ({
    backgroundImage,
    className,
    colorTheme,
    fragmentIdentifier,
    style,
    ...props
}: GenericModuleProps) => {
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        color: colorTheme?.containerText,
        ...style
    };

    return (
        <StandardModule
            backgroundImage={backgroundImage as ResponsiveImageProps}
            body={<StandardVideoContent {...{ ...props, colorTheme }} />}
            className={classNames(styles['standard-video'], className)}
            fragmentIdentifier={fragmentIdentifier}
            style={theme}
        />
    );
};

export default StandardVideo;
