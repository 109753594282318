import classNames from 'classnames';

import ContentfulRichTextWithGaEvent from '@tb-core/components/simple/contentful-rich-text-with-ga-event';
import ItemList from '@tb-core/components/simple/item-list';
import { tokenStrings } from '@tb-core/components/styled/contentful-rich-text-with-theme';
import { interpolate } from '@tb-core/helpers/interpolate';
import { FaqProps, GenericContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

interface StandardFaqItemsProps {
    isCollapsible?: boolean;
    items: FaqProps[];
    showAccordion: boolean;
}

const StandardFaqItems = ({
    isCollapsible,
    items,
    showAccordion
}: StandardFaqItemsProps) => {
    const faqItem = (item: GenericContentProps, i: number) => {
        return (
            <li className={styles['answer-container']} key={i}>
                <h3
                    dangerouslySetInnerHTML={{
                        __html: interpolate(
                            item.question as string,
                            tokenStrings
                        )
                    }}
                />
                {item.answer && (
                    <ContentfulRichTextWithGaEvent
                        gaDataLayerConfig={{
                            'Analytics-Action': 'Delivery FAQs'
                        }}
                        linkedEntries={item?.answer?.links}
                        node={item.answer.json}
                    />
                )}
            </li>
        );
    };

    return (
        <ul
            className={classNames(styles.panel, {
                [styles.accordion]: isCollapsible,
                [styles['show-accordion']]: showAccordion
            })}
        >
            <ItemList item={faqItem} itemProps={items} />
        </ul>
    );
};
export default StandardFaqItems;
